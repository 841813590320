
import { ref, defineComponent } from "vue";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./utils/firebase";
import { User } from "./models/user";
import { DrawerItem } from "./models/drawerItem";

export default defineComponent({
  name: "App",
  setup() {
    const drawer = ref<boolean | null>(null);
    const user = ref<User | null>(null);
    const drawerItems = ref<DrawerItem[]>([]);
    const myError = ref<string | null>(null);

    onAuthStateChanged(auth, async (currentUser) => {
      const idTokenResult = await currentUser?.getIdTokenResult(true);
      if (idTokenResult) {
        user.value = new User(currentUser, idTokenResult.claims);
        drawerItems.value = user.value.drawerItems();
      } else {
        drawerItems.value = [new DrawerItem("Home", "/", "mdi-home")];
      }
    });

    return {
      drawer,
      user,
      drawerItems,
      myError,
    };
  },
});
