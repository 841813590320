import apiClient from "../utils/axios";
import { auth } from "../utils/firebase";

// NOTE: https://www.bezkoder.com/vue-3-refresh-token/
const setupAxiosInterceptors = () => {
  apiClient.interceptors.request.use(
    async (config: any) => {
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { setupAxiosInterceptors };
