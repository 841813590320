import { createApp } from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import vuetify from "./plugins/vuetify";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { loadFonts } from "./plugins/webfontloader";
import { setupAxiosInterceptors } from "./plugins/axiosInterceptors";

loadFonts();
setupAxiosInterceptors();

createApp(App)
  .use(router)
  .use(vuetify)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");
