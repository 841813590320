export class DrawerItem {
  title: string;
  path: string;
  icon: string;

  constructor(title: string, path: string, icon: string) {
    this.title = title;
    this.path = path;
    this.icon = icon;
  }
}
