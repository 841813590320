import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { auth } from "@/utils/firebase";
import { onAuthStateChanged } from "firebase/auth";

const routes: Array<RouteRecordRaw> = [
  { path: "/", component: () => import("../views/Home.vue") },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/admins",
    component: () => import("../views/Admins/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admins/:id",
    component: () => import("../views/Admins/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    component: () => import("../views/Users/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users/:id",
    component: () => import("../views/Users/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/",
    component: () => import("../views/Facilities/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/import",
    component: () => import("../views/Facilities/Import.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/new",
    component: () => import("../views/Facilities/New.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/:id",
    component: () => import("../views/Facilities/_Id/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facilities/:id/edit",
    component: () => import("../views/Facilities/_Id/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facility-reports/",
    component: () => import("../views/FacilityReports/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facility-reports/:id",
    component: () => import("../views/FacilityReports/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facility-tags",
    component: () => import("../views/FacilityTags/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/facility-tags/:id",
    component: () => import("../views/FacilityTags/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/co2devices/",
    component: () => import("../views/CO2Devices/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/co2devices/:id",
    component: () => import("../views/CO2Devices/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/inquiries/",
    component: () => import("../views/Inquiries/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/inquiries/:id",
    component: () => import("../views/Inquiries/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/notices/",
    component: () => import("../views/Notices/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/batches/",
    component: () => import("../views/Batches/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sauna-sessions/",
    component: () => import("../views/SaunaSessions/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sauna-sessions/:id",
    component: () => import("../views/SaunaSessions/_Id.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/seasons",
    component: () => import("../views/Seasons/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/competitions",
    component: () => import("../views/Competitions/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/competitions/new",
    component: () => import("../views/Competitions/New.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/competitions/:id",
    component: () => import("../views/Competitions/_Id/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/competitions/:id/edit",
    component: () => import("../views/Competitions/_Id/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/notification-tests",
    component: () => import("../views/NotificationTests/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bigquery",
    component: () => import("../views/BigQuery/Index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bigquery/restore",
    component: () => import("../views/BigQuery/Restore.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  onAuthStateChanged(auth, (currentUser) => {
    if (to.path === "/login" && currentUser) {
      next({ path: "/" });
    } else if (to.meta.requiresAuth && !currentUser) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  });
});

export default router;
