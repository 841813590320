import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, { app: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_nav_icon, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
          }),
          _createVNode(_component_v_app_bar_title, null, {
            default: _withCtx(() => [
              _createTextVNode("サの国 Admin")
            ]),
            _: 1
          }),
          _createVNode(_component_v_spacer),
          (_ctx.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.user.name) + " (ログイン中)", 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_navigation_drawer, {
        app: "",
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, {
            dense: "",
            nav: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drawerItems, (item) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: item.title,
                  to: item.path,
                  "prepend-icon": item.icon,
                  title: item.title
                }, null, 8, ["to", "prepend-icon", "title"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              (_ctx.myError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_alert, { type: "error" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.myError), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}