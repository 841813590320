import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firebase = getFirestore(app);
const database = getDatabase(app);
const functions = getFunctions(app);
const functionsJP = getFunctions(app, "asia-northeast1");

// Connect to localhost if using emulators.
if (process.env.VUE_APP_ENVIRONMENT === "local") {
  connectAuthEmulator(auth, process.env.VUE_APP_FIREBASE_EMULATOR_AUTH_URL);
  connectFunctionsEmulator(
      functions,
      process.env.VUE_APP_FIREBASE_EMULATOR_FUNCTIONS_URL,
      process.env.VUE_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT,
  );
  connectFunctionsEmulator(
    functionsJP,
    process.env.VUE_APP_FIREBASE_EMULATOR_FUNCTIONS_URL,
    process.env.VUE_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT,
);
}

export { app, analytics, auth, firebase, database, functions, functionsJP };
